import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VNetworkGraph from 'v-network-graph'
import 'v-network-graph/lib/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faComputer, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'

library.add(faComputer, faCircle, faXmark)

createApp(App).use(store).use(router).use(VNetworkGraph).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
