
import {
  ForceLayout,
  ForceNodeDatum,
  ForceEdgeDatum
} from 'v-network-graph/lib/force-layout'
import { defineComponent, reactive, ref } from 'vue'
import { Nodes, Edges, Layouts } from 'v-network-graph'
import * as vNG from 'v-network-graph'
import axios from 'axios'
// import dagre
import dagre from 'dagre'
// eslint-disable-next-line
var ipRangeCheck = require('ip-range-check')

interface Node extends vNG.Node {
  size: number
  color: string
  label?: boolean
}

interface Edge extends vNG.Edge {
  width: number
  color: string
  dashed?: boolean
}

export default defineComponent({
  name: 'NetworkGraphView',
  data: () => ({
    apiendpoint: 'https://kitty.photos:8443',
    zoomLevel: 0.65,
    hoveredNodeId: '',
    newdata: {
      nodes: {} as Nodes,
      edges: {} as Edges,
      layouts: {} as Layouts,
      configs: {} as any,
      eventHandlers: {} as any,
      networks: {} as any
    },
    graphinstance: null as any,
    originalNodeData: {} as any,
    testdata: {} as any,
    nodeSize: 70
  }),
  methods: {
    showNodeInfo (nodeId: string) {
      this.hoveredNodeId = nodeId
      console.log('hoveredNodeId', nodeId)
      // const endpointNodes = this.message.filter(node => node.elem_type === 'endpoint')
      // console.log('endpointNodes', endpointNodes)
    },
    clearNodeInfo () {
      this.hoveredNodeId = ''
    },
    async downloadAsSvg () {
      console.log('downloadAsSvg')
      const graph = this.$refs.graph as vNG.Instance
      if (!graph) return
      const text = await graph.exportAsSvgText({ embedImages: true })
      const url = URL.createObjectURL(new Blob([text], { type: 'octet/stream' }))
      const a = document.createElement('a')
      a.href = url
      a.download = 'network-graph.svg'// filename to download
      a.click()
      window.URL.revokeObjectURL(url)
    },
    downloadAsJson () {
      const nodes = this.newdata.nodes
      const edges = this.newdata.edges
      const data = { nodes, edges }
      const json = JSON.stringify(data)
      const blob = new Blob([json], { type: 'application/json' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'network-graph.json' // filename to download
      a.click()
      URL.revokeObjectURL(url)
    },
    async getElements () {
      await axios.get(this.apiendpoint + '/elements').then(response => {
        // check if nodes have been deleted from api
        for (const node in this.newdata.nodes) {
          if (this.newdata.nodes[node].rawdata) {
            if (!response.data.elements.find((element: any) => element.id === this.newdata.nodes[node].rawdata.id)) {
              console.log('node deleted', this.newdata.nodes[node].rawdata.id)
              delete this.newdata.nodes[node]
            }
          }
        }
        for (const node of response.data.elements) {
          if (node.elem_type === 'endpoint') {
            let newiconcolor = 'white'
            if (node.color === 'white' || node.color === 'yellow') {
              newiconcolor = 'black'
            }
            let strokeColor = ''
            // check if this.newdata.networks is empty
            if (Object.keys(this.newdata.networks).length !== 0) {
              for (const nblocks of this.newdata.networks) {
                if (ipRangeCheck(node.id, nblocks.cidr_block)) {
                  strokeColor = nblocks.color
                }
              }
            }
            if (node.endpoint_type === 'router') {
              // @ts-ignore
              this.newdata.nodes[node.id] = { name: node.label + '\n' + node.endpoint_type.toUpperCase(), id: node.id, icon: '&#xe328', color: node.color, iconcolor: newiconcolor, networkcolor: strokeColor, rawdata: node }
            } else if (node.endpoint_type === 'switch') {
              // @ts-ignore
              this.newdata.nodes[node.id] = { name: node.label + '\n' + node.endpoint_type.toUpperCase(), id: node.id, icon: '&#xe329', color: node.color, iconcolor: newiconcolor, networkcolor: strokeColor, rawdata: node }
            } else if (node.endpoint_type === 'wap') {
              // @ts-ignore
              this.newdata.nodes[node.id] = { name: node.label + '\n' + node.endpoint_type.toUpperCase(), id: node.id, icon: '&#xe8bf', color: node.color, iconcolor: newiconcolor, networkcolor: strokeColor, rawdata: node }
            } else if (node.endpoint_type === 'firewall') {
              // @ts-ignore
              this.newdata.nodes[node.id] = { name: node.label + '\n' + node.endpoint_type.toUpperCase(), id: node.id, icon: '&#xef55', color: node.color, iconcolor: newiconcolor, networkcolor: strokeColor, rawdata: node }
            } else if (node.endpoint_type === 'server') {
              // @ts-ignore
              this.newdata.nodes[node.id] = { name: node.label + '\n' + node.endpoint_type.toUpperCase(), id: node.id, icon: '&#xe1db', color: node.color, iconcolor: newiconcolor, networkcolor: strokeColor, rawdata: node }
            } else if (node.endpoint_type === 'workstation') {
              // @ts-ignore
              this.newdata.nodes[node.id] = { name: node.label + '\n' + node.endpoint_type.toUpperCase(), id: node.id, icon: '&#xe30a', color: node.color, iconcolor: newiconcolor, networkcolor: strokeColor, rawdata: node }
            } else if (node.endpoint_type === 'iot_device') {
              // @ts-ignore
              this.newdata.nodes[node.id] = { name: node.label + '\n' + node.endpoint_type.toUpperCase(), id: node.id, icon: '&#xf0eb', color: node.color, iconcolor: newiconcolor, networkcolor: strokeColor, rawdata: node }
            } else if (node.endpoint_type === 'ics_device') {
              // @ts-ignore
              this.newdata.nodes[node.id] = { name: node.label + '\n' + node.endpoint_type.toUpperCase(), id: node.id, icon: '&#xf049', color: node.color, iconcolor: newiconcolor, networkcolor: strokeColor, rawdata: node }
            } else if (node.endpoint_type === 'phone') {
              // @ts-ignore
              this.newdata.nodes[node.id] = { name: node.label + '\n' + node.endpoint_type.toUpperCase(), id: node.id, icon: '&#xe325', color: node.color, iconcolor: newiconcolor, networkcolor: strokeColor, rawdata: node }
            } else {
              // @ts-ignore
              this.newdata.nodes[node.id] = { name: node.label + '\n' + node.endpoint_type.toUpperCase(), id: node.id, icon: '&#xe339', color: node.color, iconcolor: newiconcolor, networkcolor: strokeColor, rawdata: node }
            }
          }
        }

        // get edges
        for (const node of response.data.elements) {
          const interfaceFrom = node.interface_from
          const interfaceTo = node.interface_to
          // check if edge has been deleted from api
          if (this.newdata.edges[node.id]) {
            if (!response.data.elements.find((element: any) => element.id === this.newdata.edges[node.id].rawdata.id)) {
              console.log('edge deleted', this.newdata.edges[node.id].rawdata.id)
              delete this.newdata.edges[node.id]
            }
          }
          if (node.elem_type === 'connection' && interfaceFrom && interfaceTo) {
            if (node.line_type === 'dashed') {
              // @ts-ignore
              this.newdata.edges[node.id] = {
                source: interfaceFrom.split('_')[0],
                target: interfaceTo.split('_')[0],
                dashed: true,
                color: node.color,
                animate: true
              }
            } else if (node.line_type === 'dotted') {
              // @ts-ignore
              this.newdata.edges[node.id] = {
                source: interfaceFrom.split('_')[0],
                target: interfaceTo.split('_')[0],
                dashed: true,
                color: node.color,
                dotted: true,
                animate: true
              }
            } else {
              // @ts-ignore
              this.newdata.edges[node.id] = {
                source: interfaceFrom.split('_')[0],
                target: interfaceTo.split('_')[0],
                color: node.color
              }
            }
          }
        }

        // get networks
        for (const node of response.data.elements) {
          if (node.elem_type === 'network') {
            console.log(node)
            // check if node ip is in cidr block
            const nodesInCidrBlock = [] as any
            for (const ch of response.data.elements) {
              if (ch.elem_type === 'endpoint') {
                if (ipRangeCheck(ch.id, node.cidr_block)) {
                  this.newdata.nodes[ch.id].networkcolor = node.color
                  console.log(this.newdata.nodes[ch.id].networkcolor)
                }
              }
            }
            console.log(nodesInCidrBlock)
            // @ts-ignore
            this.newdata.networks[node.id] = { id: node.id, name: node.label, color: node.color, cidr_block: node.cidr_block }
          }
        }
        console.log(this.newdata.nodes)
      })
    },
    // layout methods
    layout (direction: 'TB' | 'LR') {
      if (Object.keys(this.newdata.nodes).length <= 1 || Object.keys(this.newdata.edges).length === 0) {
        return
      }
      const graph = ref<vNG.VNetworkGraphInstance>()

      // convert graph
      const g = new dagre.graphlib.Graph()
      g.setGraph({
        rankdir: direction,
        nodesep: this.nodeSize * 2,
        edgesep: this.nodeSize,
        ranksep: this.nodeSize * 2
      })
      // default to assigning a new object as a label for each new edge
      g.setDefaultEdgeLabel(() => ({}))

      // add nodes to graph
      Object.entries(this.newdata.nodes).forEach(([nodeId, node]) => {
        g.setNode(nodeId, { label: node.name, width: this.nodeSize, height: this.nodeSize })
      })

      // add edges to graph
      Object.values(this.newdata.edges).forEach(edge => {
        g.setEdge(edge.source, edge.target)
      })

      dagre.layout(g)

      const box: Record<string, number | undefined> = {}
      g.nodes().forEach((nodeId: string) => {
        // update position
        const x = g.node(nodeId).x
        const y = g.node(nodeId).y
        this.newdata.layouts.nodes[nodeId] = { x, y }

        // calculate bounding box
        box.top = box.top ? Math.min(box.top, y) : y
        box.bottom = box.bottom ? Math.max(box.bottom, y) : y
        box.left = box.left ? Math.min(box.left, x) : x
        box.right = box.right ? Math.max(box.right, x) : x
      })

      const graphMargin = this.nodeSize * 2
      const viewBox = {
        top: (box.top ?? 0) - graphMargin,
        bottom: (box.bottom ?? 0) + graphMargin,
        left: (box.left ?? 0) - graphMargin,
        right: (box.right ?? 0) + graphMargin
      }
      graph.value?.setViewBox(viewBox)
    },
    updateLayout (direction: 'TB' | 'LR') {
      this.layout(direction)
    }
  },
  mounted () {
    // init graph
    this.graphinstance = ref<vNG.VNetworkGraphInstance>()
    // set the config
    this.newdata.configs = reactive(
      vNG.defineConfigs<Node, Edge>({
        node: {
          normal: {
            color: node => node.color,
            radius: this.nodeSize / 2,
            strokeWidth: 5,
            strokeColor: node => node.networkcolor,
            strokeDasharray: '0'
          },
          label: {
            color: '#ffffffff'
          }
        },
        edge: {
          normal: {
            width: edge => (edge.animate ? 2 : 1),
            color: edge => edge.color,
            dasharray: edge => (edge.animate ? (edge.dotted ? '4' : '50') : '0'),
            animate: edge => !!edge.animate
          }
        },
        view: {
          zoomEnabled: true,
          maxZoomLevel: 2,
          minZoomLevel: 0.5,
          layoutHandler: new ForceLayout({
            positionFixedByDrag: false,
            positionFixedByClickWithAltKey: true,
            createSimulation: (d3, networks, edges) => {
              const forceLink = d3.forceLink<ForceNodeDatum, ForceEdgeDatum>(edges).id((d: any) => d.id)
              return d3
                .forceSimulation(networks)
                .force('edge', forceLink.distance(500))
                .force('charge', d3.forceManyBody())
                .force('collide', d3.forceCollide(150).strength(0.5))
                .force('center', d3.forceCenter().strength(0.05))
                .alphaMin(0.001)
            }
          })
        }
      })
    )
    // set the layout
    // get elements from api
    this.getElements()
    this.layout('LR')
    // loop on timer to check api for new data
    setInterval(() => {
      this.getElements()
      console.log('polling api for new elements')
    }, 5000)
  }
})
