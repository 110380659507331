
import { defineComponent } from 'vue'
import NavbarComp from '@/components/Navbar.vue'

export default defineComponent({
  name: 'App',
  components: {
    NavbarComp
  }
})
